import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

import { JaneEmbedConstants } from "./constants";

// import { useJaneContext } from "./context";
import {
  buildJaneOptions,
  handleWindowMessages,
  handleResize,
  setDistanceToTop,
} from "./index";

const JaneEmbed = (props) => {
  // const context = useJaneContext();

  // jane wants a document reference
  const thisDoc =
    typeof document === "object"
      ? document.getElementsByTagName("body")[0]
      : null;
  const janeBodyRef = useRef(thisDoc);

  // DOM refs
  const janeEmbedMenuDivRef = useRef(null);
  const janeEmbedIframeDivRef = useRef(null);
  const janeEmbedRef = useRef(null);

  // not-DOM refs
  // const janeEmbedLoadedRef = useRef(false);
  const janeResizedMessageTimerRef = useRef();
  const janeRenderKeyRef = useRef(nanoid());
  const janeIntersectionObserverRef = useRef();
  const janeResizeObserverRef = useRef();

  const uicontext = {
    janeBodyRef,
    janeEmbedMenuDivRef,
    janeEmbedIframeDivRef,
    janeEmbedRef,
    janeResizedMessageTimerRef,
    janeRenderKeyRef,
    janeIntersectionObserverRef,
    janeResizeObserverRef,
  };

  useEffect(() => {
    const resizeCb = () => handleResize(uicontext);
    const messageCb = () => handleWindowMessages(event, uicontext, props);

    uicontext.janeIntersectionObserverRef.current = new IntersectionObserver(
      function () {}
    );
    uicontext.janeResizeObserverRef.current = new ResizeObserver(resizeCb);

    window.addEventListener("resize", resizeCb);

    const script = document.createElement("script");
    script.id = "jane-embed";
    script.src = JaneEmbedConstants.EMBEDDED_SRC;
    script.async = true;
    document.getElementById("jane").appendChild(script);

    window.addEventListener("message", messageCb, false);

    return () => {
      uicontext.janeIntersectionObserverRef.current = null;
      uicontext.janeResizeObserverRef.current = null;
      window.removeEventListener("resize", resizeCb);
      window.removeEventListener("message", messageCb, false);
    };
  }, []);

  useEffect(() => {
    // debugger;
    const menuDiv = document.getElementById("menu-div");
    uicontext.janeEmbedMenuDivRef.current = menuDiv;

    const iframeDiv = document.getElementById("iframe-div");
    uicontext.janeEmbedIframeDivRef.current = iframeDiv;

    const frame = document.getElementById(JaneEmbedConstants.JANE_EMBED_ID);
    uicontext.janeEmbedRef.current = frame;

    if (frame && frame.contentWindow) {
      uicontext.janeResizeObserverRef.current.observe(frame);
      uicontext.janeResizeObserverRef.current.observe(
        uicontext.janeBodyRef.current
      );

      frame.setAttribute("scrolling", "no");

      // try {
      //   const deviceId =
      //     window.localStorage.getItem("j_device_id") ||
      //     window.localStorage.setItem("j_device_id", nanoid());
      //   props.actions.setJaneDeviceId({ args: { deviceId: deviceId } });
      // } catch (err) {
      //   console.log("localStorage error", err);
      // }

      setDistanceToTop(uicontext.janeEmbedMenuDivRef, props);

      // send Jane init payload
      props.actions.setJaneOptions(props.janeData);
      const opts = buildJaneOptions(props.janeData.embedConfig.action, {
        cartData: props.janeData.embedConfig.cartData || null,
        storeId: props.janeData.embedConfig.storeId || null,
        themeColor: props.janeData.embedConfig.themeColor,
        navigationColor: props.janeData.embedConfig.navigationColor,
        ctaTextColor: props.janeData.embedConfig.ctaTextColor,
        buttonBorderRadius:
          props.janeData.embedConfig.buttonBorderRadius || "0.5rem",
        cognitoToken: props.janeData.embedConfig.cognitoToken,
        userData: props.janeData.embedConfig.userData,
      });
      frame.contentWindow.postMessage(opts, "*");
    }
  }, [props.janeData.janeEmbedLoaded]);

  return (
    <div id="jane">
      <div id="jane-embed"></div>
      <div id="jane-frame-script"></div>
    </div>
  );
};

JaneEmbed.propTypes = {
  janeData: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

JaneEmbed.displayName = "JaneEmbed";

export default JaneEmbed;
