import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";

// import Button from "../components/button/Button";
import JaneEmbed from "../../../../common/providers/iheartjane/jane-embed-v2/JaneEmbed";
import LoadingSVG from "../../../../common/elements/icons/LoadingSVG";

import * as JaneStyles from "../styles/jane.module.css";

const Checkout = (props) => {
  const state = useSelector((state) => state);
  const store = getReduxStore("init", state.router.location.pathname).store;
  const actions = getBoundActions(store.dispatch);
  const siteConfigData = useSelector((state) => state.hydrate.siteConfigData);

  const user = state.auth.currentUser;

  return (
    <JaneCheckout
      user={user}
      cartData={state.session.cartData}
      siteConfigData={siteConfigData}
      janeData={state.jane}
      janeActions={actions.jane}
      doSetCartData={actions.session.doSetCartData}
      authFeature={
        state.hydrate.siteConfigData.features?.authentication?.status
      }
    />
  );
};

export default Checkout;

const JaneCheckout = React.memo((props) => {
  const user = props.user;
  const {
    janeCheckoutCompleted,
    janeCheckoutUpdatedCart,
    mounted,
    embedConfig,
    cartItemAdded,
    cartPageLoadData,
  } = props.janeData;

  // const [mounted, setMounted] = useState(false)
  // const [janeCheckoutCompleted, setJaneCheckoutCompleted] = useState(null)
  // const [janeCheckoutUpdatedCart, setJaneCheckoutUpdatedCart] = useState(null)

  // @todo: if going directly to checkout this needs to wait for cognito token
  const janeEmbedConfig = {
    iframeSrc: `https://www.iheartjane.com/headless/cart`,
    storeId: props.cartData.store.janeId,
    themeColor: "#345543",
    navigationColor: "#8cbb66",
    ctaTextColor: "#ffffff",
    action: "buildCart",
    cartData: props.cartData,
  };

  if (props.authFeature !== "disabled") {
    janeEmbedConfig.userData = {
      firstName: user?.attributes?.given_name,
      lastName: user?.attributes?.family_name,
      birthDate: user?.attributes?.birthdate,
      phone: user?.attributes?.phone_number?.slice(2), // @todo: do more cleanly
      email: user?.attributes?.email,
      externalId: user?.attributes?.sub,
    };
    janeEmbedConfig.cognitoToken = user
      ? user?.signInUserSession?.accessToken?.jwtToken
      : null;
  }

  useEffect(() => {
    props.janeActions.setMounted();
  }, []);

  useEffect(() => {
    props.janeActions.setEmbedConfig(janeEmbedConfig);
  }, [props.cartData, user]);

  useEffect(() => {
    if (janeCheckoutCompleted) {
      if (typeof window === "object" && window.twq) {
        window.twq("event", "tw-ofxar-ofxaw", {
          value: janeCheckoutCompleted.properties.estimatedTotal,
          currency: "USD",
        });
      }
      const cartData = props.cartData;
      localStorage.setItem(
        "tgs-jane-cart-" + janeCheckoutCompleted.properties.cartId,
        JSON.stringify(cartData)
      );
      cartData.cartCompleted = true;
      props.doSetCartData(cartData);
      props.janeActions.setCartPageLoadData(null);
      navigate(
        "/checkout/success?id=" + janeCheckoutCompleted.properties.cartId
      );
    }
  }, [janeCheckoutCompleted]);

  const getProductById = async (url, requestOptions) => {
    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      return data?.results[0]?.hits[0];
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (janeCheckoutUpdatedCart) {
        const path = `https://${props?.siteConfigData?.env?.jane?.application_id}-dsn.algolia.net/1/indexes/*/queries`;

        if (typeof window === "object") {
          const scrollTo = document.getElementById("app");
          window.scroll({ top: scrollTo, behavior: "smooth" });
        }

        const cartData = { ...props.cartData }; // Clone cartData to avoid mutations

        const foundMap = {};

        for (const val of janeCheckoutUpdatedCart) {
          foundMap[val.productId] = true;
          const cartIndex = cartData.products.findIndex(
            (item) => item.productId === val.productId
          );

          if (~cartIndex) {
            cartData.products[cartIndex].count = val.count;
          } else if (
            cartItemAdded &&
            cartItemAdded.productId === val.productId
          ) {
            props.janeActions.setCartItemAdded(null);
            const raw = JSON.stringify({
              requests: [
                {
                  indexName: "menu-products-production",
                  params: `filters=product_id:${val.productId}`,
                },
              ],
            });

            const requestOptions = {
              method: "POST",
              body: raw,
              headers: {
                "X-Algolia-Application-Id":
                  props?.siteConfigData?.env?.jane?.application_id,
                "X-Algolia-API-Key":
                  props?.siteConfigData?.env?.jane?.algolia_key,
              },
            };

            try {
              const productData = await getProductById(path, requestOptions);
              cartData.products.push({
                productId: val.productId,
                priceId: val.priceId,
                count: val.count,
                name: productData?.name || "",
                brand: productData?.brand || "",
                photo: productData?.image_urls[0] || "",
                price: parseFloat(productData[`price_${val.priceId}`]),
              });
            } catch (error) {
              console.warn(error);
              // Handle or log the error
            }
          }
        }

        cartData.products = cartData.products.filter(
          (val) => foundMap[val.productId]
        );

        props.doSetCartData(cartData);
      }
    };

    fetchData();
  }, [janeCheckoutUpdatedCart]);

  if (!props.cartData.store.janeId) {
    return (
      <div>
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <h1>Select a store to shop!</h1>
        </div>
      </div>
    );
  }

  return mounted && embedConfig ? (
    <div>
      <JaneEmbed
        actions={props.janeActions}
        janeData={props.janeData}
        loader={LoadingSVG}
      />
    </div>
  ) : null;
});
